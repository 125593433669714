.pagination {
  padding: 0;
  margin: 50px 0 25px;
  text-align: center;
  list-style-type: none;

  li {
    display: inline-block;
  }
}

.pagination__page-button {
  border-width: 0;
  background-color: transparent;
  padding: 5px 10px;
  font-weight: $font-weight-semibold;

  &:hover {
    cursor: pointer;
  }
}

.pagination__current-page {
  font-weight: $font-weight-bold;
  color: $color-primary;
  font-size: $font-size-button;
  padding: 5px 10px;
}
