.article__title {
  font-family: $font-family-basic;
  font-weight: $font-weight-bold;
  margin: 3rem 0 1.5rem;
  color: $color-grey--dark;
  font-size: $font-size-h1;
}

.article__date {
  font-size: $font-size-small;
}

.article__image {
  margin: 1rem 0;
  text-align: center;
  height: 300px;
  min-height: 300px;

  img {
    display: inline-block;
    height: 300px;
    min-height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

.article__lead {
  font-family: $font-family-basic;
  font-size: $font-size-lead;
  font-style: italic;
  font-weight: $font-weight-regular;
  letter-spacing: -0.2px;
  line-height: 1.35;
  margin-bottom: 1rem;
}

.article__text {
  margin-bottom: 50px;

  p {
    font-family: $font-family-basic;
    font-size: $font-size-basic;
    font-weight: $font-weight-regular;
    margin-bottom: 0.5rem;
  }

  a {
    color: $color-primary;
  }
}

.related-galleries,
.related-articles {
  margin-bottom: 50px;
}

.related-galleries__title,
.related-articles__title {
  font-weight: $font-weight-semibold;
  font-size: $font-size-article-section-title;
  margin: 0 0 1rem;
}

.related-articles__list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.related-articles__article {
  margin-bottom: 1rem;
  a {
    display: flex;
    flex-direction: column;
  }
}

.related-articles__article-image {
  text-align: center;

  img {
    height: 180px;
    display: inline-block;
    width: 100%;
    object-fit: cover;
  }
}

.related-articles__article-title {
  color: $color-text;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
  line-height: 1.2;
  margin-bottom: 0;
}

@include media(">=sm") {
  .article__image,
  .article__image img {
    height: 400px;
    min-height: 400px;
  }

  .article__lead {
    line-height: 35px;
  }

  .related-articles__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
