.richtext-content {
  padding-bottom: 50px;
  overflow: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-basic;
    font-weight: $font-weight-bold;
    margin: 3rem 0 1.5rem;
    color: $color-grey--dark;
  }

  h1:first-of-type {
    margin-top: 0;
  }

  p,
  li {
    font-family: $font-family-basic;
    font-size: $font-size-basic;
    font-weight: $font-weight-regular;
  }

  p {
    margin-bottom: 0.5rem;
  }

  a {
    color: $color-primary;
  }

  img,
  .richtext-image {
    margin: 1rem 0;
    display: inline-block;

    &.full-width {
      display: block;
      width: 100%;
      height: auto;
      margin: 1rem auto;
    }

    &.right,
    &.left {
      max-width: 100%;
      width: 100%;
    }
  }
}

@include media(">=sm") {
  img,
  .richtext-image {
    &.right,
    &.left {
      max-width: 50%;
      width: auto;
    }

    &.right {
      float: right;
      margin-left: 1rem;
    }

    &.left {
      float: left;
      margin-right: 1rem;
    }
  }
}
