.gallery {
  margin-bottom: 50px;
}

.gallery__container {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.gallery__item {
  max-height: 250px;
}

@include media(">=sm") {
  .gallery__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
