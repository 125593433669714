.navigation-tree {
  width: 300px;
  min-width: 300px;
  background-color: $color-grey-background;
  padding: 25px 30px;
  margin-right: 25px;
  font-size: $font-size-small;
}

.navigation-tree__title {
  color: $color-text;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

.navigation-tree__list {
  list-style-type: none;

  &.tree-level-1 {
    padding: 0;
    margin-bottom: 0;
  }

  &.tree-level-2 {
    padding: 1rem 0 0 1rem;
  }
}

.navigation-tree__list-item {
  padding-bottom: 1rem;

  li:last-of-type {
    padding-bottom: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
}

.navigation-tree__link {
  color: $color-text;
}
