.navbar {
  .container {
    min-height: 108px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.active-lang {
  font-weight: $font-weight-bold;
  color: $color-primary;
}

.language-button {
  border-width: 0;
  background-color: transparent;
  font-family: $font-family-basic;
  font-size: $font-size-small;
  color: $color-primary;
  display: flex;
  align-items: center;
}

.search-form {
  display: flex;
  align-content: center;
}

.search-form__input {
  @extend %input;

  max-width: 150px;
}

.search-form__button {
  border-width: 0;
  background-color: transparent;
  padding: 0;
  min-width: 30px;
}

.navbar__logo {
  display: none;
  padding-right: 10px;

  a:hover {
    text-decoration: none;
  }

  img {
    width: 70px;
    height: auto;
  }
}

.navbar__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbar__top-row {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.navbar__bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.navbar__menu {
  display: none;
  width: calc(100% - 117px);
  padding-left: 2rem;
}

.navbar__languages {
  margin-left: 20px;
  display: flex;
  align-items: center;

  form {
    display: flex;
  }
}

@include media(">=sm") {
  .search-form__input {
    max-width: none;
  }

  .search-form__button {
    padding: 10px;
  }

  .navbar__logo,
  .navbar__menu {
    display: block;
  }

  .navbar__content {
    width: calc(100% - 80px);
  }

  .navbar__top-row {
    justify-content: flex-end;
  }
}
