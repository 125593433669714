.search-results {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 50px;
}

.search-results__title {
  text-align: center;
  margin-bottom: 3rem;
}

.search-item {
  margin-bottom: 2rem;
}

.search-item__title {
  margin-bottom: 0.5rem;
}
