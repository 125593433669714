.tiles-section {
  margin-bottom: 50px;
}

.tiles-section__title {
  color: $color-primary;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
}

.featured-article {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.featured-article__image,
.featured-article__video {
  width: 100%;
  min-height: 330px;

  img,
  iframe {
    min-height: 330px;
    width: 100%;
    max-width: 100%;
  }

  img {
    object-fit: cover;
  }
}

.featured-article__content {
  width: 100%;
  margin-top: 1rem;
}

.featured-article__title {
  font-size: $font-size-h2;
  margin: 0 0 0.75rem;
}

.featured-article__date {
  font-size: $font-size-very-small;
  display: block;
  margin-bottom: 0.75rem;
}

.featured-article__lead {
  margin-bottom: 1rem;
  * {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
  }
}

.small-articles-list,
.embed-tiles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.embed-tiles-list {
  display: block;
}

.small-article,
.embed-tile {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;

  .read-more {
    padding-top: 1rem;
    font-size: $font-size-small;
  }
}

.small-article__image,
.small-article__video,
.embed-tile__image,
.embed-tile__video {
  height: 200px;
  min-height: 200px;

  img {
    height: 200px;
    min-height: 200px;
    width: 100%;
    object-fit: cover;
  }

  iframe {
    height: 200px;
    min-height: 200px;
    width: 100%;
  }
}

.small-article__content,
.embed-tile__content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px);
  padding-top: 1rem;
}

.small-article__title,
.embed-tile__title {
  font-size: $font-size-h3;
  margin: 0 0 0.75rem;
}

.small-article__date,
.embed-tile__date {
  font-size: $font-size-very-small;
  margin-bottom: 0.75rem;
  color: $color-text;
  display: block;
}

.small-article__lead,
.embed-tile__lead {
  color: $color-text;
  font-size: $font-size-basic;

  * {
    color: $color-text;
    font-weight: $font-weight-semibold;
    font-size: $font-size-basic;
  }
}

.featured-embed-tile {
  grid-column: 1 / span 3;
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .embed-tile__video {
    width: 100%;
    min-height: 330px;

    iframe {
      min-height: 330px;
      width: 100%;
      max-width: 100%;
    }
  }

  .embed-tile__content {
    width: 100%;
    margin-top: 1rem;

    * {
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
    }
  }
}

@include media(">=sm") {
  .featured-article,
  .featured-embed-tile {
    flex-direction: row-reverse;
  }

  .featured-article__image,
  .featured-article__video {
    width: 55%;
  }

  .featured-article__content {
    margin-top: 0;
    width: 40%;
  }

  .small-articles-list,
  .embed-tiles-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .embed-tiles-list {
    display: grid;
  }

  .small-article,
  .embed-tile {
    width: auto;
  }

  .featured-embed-tile {
    .embed-tile__video {
      width: 55%;
    }

    .embed-tile__content {
      width: 40%;
      margin-top: 0;
    }
  }
}
