.mobile-menu__icon {
  display: block;
  width: 24px;
  height: 24px;
  background: url("../images/icon-menu.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.mobile-menu__close-icon {
  cursor: pointer;
}

.mobile-menu__container {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  left: -230px;
  width: 230px;
  padding: 1.5rem 1rem;
  background-color: white;
  z-index: 101;
  transition: left 0.5s ease-out;

  &.open {
    left: 0;
    transition: left 0.5s ease-in;
  }

  &.hidden {
    display: none;
  }

  .button--primary {
    width: 100%;
    display: block;
    margin-top: 30px;
    font-weight: $font-weight-bold;
  }

  .menu__item .submenu {
    display: block;
  }

  .submenu__link {
    padding: 0;
  }

  .menu__link {
    width: 100%;
  }
}

.mobile-menu__list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  > ul {
    margin: 30px 0 0 1rem;
  }
  li {
    padding: 8px 0;
    ul {
      padding-left: 1rem;
    }
    li:last-of-type {
      padding-bottom: 0;
    }
  }

  .menu__item {
    display: block;
  }

  .menu__link {
    color: $color-grey--dark;
    padding: 0;
    display: inline-block;

    &:hover,
    &.active-link {
      color: $color-primary;
    }
  }
}

.mobile-menu__overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: $color-overlay;
  z-index: 99;

  &.open {
    width: 100%;
  }
  &.hidden {
    display: none;
  }
}

@include media(">=sm") {
  .mobile-menu__icon {
    display: none;
  }
}
