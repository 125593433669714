@import "breakpoints";
@import "variables";

// Buttons
@mixin button {
  border-width: 1px;
  border-style: solid;
  padding: 12px 18px;
  font-weight: $font-weight-regular;
  display: inline-block;
  text-align: center;
}

@mixin button--primary {
  @include button;
  color: white;
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover {
    color: $color-primary;
    background-color: white;
  }
}

@mixin button--secondary {
  @include button;
  color: $color-primary;
  background-color: white;
  border-color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: white;
  }
}
