* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;
  color: $color-text;
  line-height: 1.45;
  font-family: $font-family-basic;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 0;
}

.container-with-navigation-tree {
  display: flex;
}

img {
  max-width: 100%;
}

p {
  font-weight: $font-weight-regular;
  margin: 0;
}

a {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.link {
  color: $color-primary;
  font-weight: $font-weight-semibold;
  border-width: 0;
  padding: 0.75rem 0.5rem;
  background-color: transparent;
  display: inline-block;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.read-more {
  font-weight: $font-weight-semibold;
}

.read-more-icon {
  height: 12px;
  padding-left: 5px;
}

// headers
h1,
.h1 {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: $font-weight-bold;
  color: $color-grey--dark;
}

h2,
.h2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-semibold;
  color: $color-grey--dark;
}

h3,
.h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-semibold;
  color: $color-grey--dark;
}

// buttons

.button {
  @include button;

  &:hover {
    cursor: pointer;
  }
}

.button--primary {
  @include button--primary;
}

.button--secondary {
  @include button--secondary;
}

// forms

%input {
  border: 1px solid $color-grey-border;
  padding: 5px 10px;
  color: $color-text;
  font-family: $font-family-basic;
  height: 35px;

  &:focus {
    border-color: $color-primary;
  }
}

.input {
  @extend %input;
}

.label {
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

@include media(">=sm") {
  h1,
  .h1 {
    font-size: $font-size-h1;
    line-height: normal;
  }

  .container {
    max-width: $container-width;
    padding: 80px 0 0;
  }
}
