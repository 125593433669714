.menu {
  margin: 0;
  padding: 0;
}

.menu__item {
  display: inline-block;
  // This is needed to anchor child menu (has position: absolute), even when page is scrolled
  position: relative;
}

.menu__link {
  font-size: $font-size-basic;
  color: $color-grey--dark;
  letter-spacing: 0.1px;
  position: relative;
  padding: 1rem 0.6rem;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: $color-primary;
    text-decoration: underline;
  }

  &.active {
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
}

.menu__chevron-icon {
  cursor: pointer;
  padding-left: 0.3rem;
}

.submenu {
  list-style: none;
  padding: 0.25rem 0.25rem;
  margin: 0;
}

.menu__item .submenu {
  display: none;
}

.tippy-content .submenu {
  display: block;
}

.submenu___item {
  display: block;
  padding: 0.5rem 1rem;
}

.submenu__link {
  color: $color-text;
  font-size: 1rem;
  display: block;
  padding: 0.25rem 0;

  &:hover {
    color: $color-primary;
    text-decoration: underline;
  }
}

.js-dropdown-visible {
  .submenu {
    display: block;
    opacity: 1;
    z-index: 20;
  }

  .menu__chevron-icon {
    transform: rotate(180deg);
    padding: 0 0.5rem 0 0;
  }
}
