// containers
$container-width: 1100px;
$narrow-container-width: 750px;

// colors
$color-primary: #7a1c1c;
$color-text: #737b7d;
$color-grey--light: #c4c4c4;
$color-grey--dark: #373f41;
$color-grey-border: #e0e0e0;
$color-grey-background: #fbfbfb;
$color-error: #cf1313;
$color-overlay: #00000050;

// typography
$font-family-basic: "Mulish", sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-basic: 1rem; // 16px
$font-size-h1: 2.75rem; // 44px
$font-size-h2: 2rem; // 32px
$font-size-h3: 1.125rem; // 18px
$font-size-article-section-title: 1.5625rem; // 25px
$font-size-lead: 1.125rem; // 18px
$font-size-button: 0.875rem; // 14px
$font-size-small: 0.875rem; // 14px
$font-size-very-small: 0.75rem; // 12px
