.footer {
  background-color: $color-grey-background;
  color: $color-grey--dark;
  padding: 36px 0;
  flex-shrink: 0;
}

.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;

  a {
    color: $color-text;
    text-decoration: underline;
  }
}

.footer__content-right {
  padding-top: 1rem;
}

.footer__top-row,
.footer__bottom-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer__logo {
  padding-bottom: 1rem;
  width: 100%;

  p {
    font-size: $font-size-small;
  }
}

.footer__partners {
  width: 100%;
}

.footer__partners-logo-link {
  display: inline-block;
  padding-left: 0.5rem;
}

.footer__bottom-row {
  padding-top: 30px;
}

.footer__copyright {
  color: $color-grey--light;
  text-align: right;
}

.footer__social-media {
  display: flex;
}

.footer__social-media-link {
  .bip-icon {
    max-width: 130px;
    padding: 8px 0 0 8px;
  }

  &:hover {
    text-decoration: none;
  }
}

@include media(">=xs") {
  .footer__content,
  .footer__top-row,
  .footer__bottom-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__content-left,
  .footer__content-right {
    max-width: 49%;
  }

  .footer__content-right {
    text-align: right;
    padding-top: 0;
  }

  .footer__logo {
    padding-right: 1rem;
    padding-bottom: 0;
    width: auto;
  }

  .footer__partners {
    padding-left: 1rem;
    width: auto;
  }
}
